.home {
  display: flex;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .home {
    flex-direction: column;
  }
}
